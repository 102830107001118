import { LOGOUT, TOKEN_NOT_RECEIVED, USER_INITIALIZED, userActions, userSelectors } from '@rosetta/gaia-data';
import { initAudioAutoplayWorkaround } from '@rosetta/gaia-media';
import { lionActions, lionSelectors } from '@rosetta/react-lion';
import queryString from 'query-string';
import { Cookies } from 'react-cookie';
import { delay, call, fork, put, select, take, takeEvery, takeLatest, spawn } from 'redux-saga/effects';
import {
  appActions,
  appSelectors,
  HANDLE_ERROR,
  INITIALIZE_APP,
  SET_APP_FONT_FOR_LANGUAGE,
  INSTALL_LION_LANGUAGE
} from 'modules/app/redux/app-reducer';
import { initGoogleTagManagerCallback } from '../google/google-tag-sagas';
import { logger } from 'modules/app/utils/logger';
import { initForMultipleInstances, isThisInstanceActive, removeAllInstanceIds } from '../utils/single-instance-utils';

const FALLBACK_LOCALE = 'en-US';
const TOKEN_RECEIVED = 'TOKEN_RECEIVED';

const cookieName = 'RSID';
const cookies = new Cookies();

const ACTIVE_STATUS_POLLING_INTERVAL_MS = 1500;

let pollingActiveStatusFlag = false;
function* startPollingActiveStatus() {
  if (pollingActiveStatusFlag) {
    return;
  }
  pollingActiveStatusFlag = true;
  while (pollingActiveStatusFlag) {
    const isActive = isThisInstanceActive();
    if (!isActive) {
      logger.info('logging out because not an active instance');
      return (window.location.pathname = '/logout');
    }
    yield delay(ACTIVE_STATUS_POLLING_INTERVAL_MS);
  }
}

export const stopPollingActiveStatus = () => {
  pollingActiveStatusFlag = false;
};

function* initAppSaga(action) {
  try {
    // ** LION things
    // note that resourcesLoaded is false on startup - but I want to be explicit about it right here
    yield put(lionActions.setLionAppResourcesLoaded(false));
    // load up the strings used by the fallback language and install them in lion
    const newStrings = yield fetch(`/resources/${FALLBACK_LOCALE}.json`).then((res) => res.json());
    yield put(lionActions.setLionAppResources(newStrings, FALLBACK_LOCALE));
    yield put(lionActions.setLionFallbackLanguage(FALLBACK_LOCALE));
    // the startup value for lionLanguage is en-US, this call is just to make that *explicit*
    yield put(lionActions.setLionLanguage(FALLBACK_LOCALE));

    // this next block is a dev-thing so we can test and take screenshots
    //  it sets an overrideUILanguage which is used as the user's UI language
    //  it must be called *before* userInit to be successful; it should be OK here
    const parsedQueryParams = queryString.parse(action.payload.queryParams);
    if (parsedQueryParams.language) {
      yield put(userActions.setUILanguage(parsedQueryParams.language));
    }

    yield call(initAudioAutoplayWorkaround);

    const isLoggedIn = yield select(userSelectors.isLoggedIn);
    if (!isLoggedIn) {
      // when accessing from launchpad, there should be a cookie containing the access token
      const cookies = new Cookies();
      const cookie = cookies.get(cookieName);
      const cookieToken = cookie ? cookie.access_token : null;
      // when refreshing page, token is kept in sessionStorage
      const token = sessionStorage.getItem('token');
      const recentToken = cookieToken ? cookieToken : token; // Use the most recent token from the gaia-web session.
      if (recentToken) {
        yield put(userActions.loginViaToken(recentToken));
        yield take([USER_INITIALIZED, TOKEN_NOT_RECEIVED, HANDLE_ERROR]);
      }
    }

    // set up for handling case where users open GW in multiple tabs
    yield call(initForMultipleInstances);
    yield fork(startPollingActiveStatus);
    yield spawn(initGoogleTagManagerCallback);

    yield put(appActions.setAppInitialized());
  } catch (ex) {
    yield put(appActions.handleError('initAppSaga fail: ' + ex.message));
  }
}

function* setAppFontForLanguageSaga() {
  const appFont = yield select(appSelectors.appFont);
  if (appFont) {
    document.body.style.fontFamily = appFont;
  }
}

function* setLionLanguageSaga(action) {
  const currLanguage = yield select(lionSelectors.getLionLanguage);
  const newLanguage = action.payload.locale;

  if (newLanguage !== currLanguage) {
    yield put(lionActions.setLionAppResourcesLoaded(false));
    const newStrings = yield fetch(`/resources/${newLanguage}.json`).then((res) => res.json());
    yield put(lionActions.setLionAppResources(newStrings, newLanguage));
    yield put(lionActions.setLionLanguage(newLanguage));
  }
  yield put(lionActions.setLionAppResourcesLoaded(true));
}

function* handleError(action) {
  const airbrakeId = yield call(logger.sendToAirbrake, {
    message: action.payload.message,
    params: {
      ...action.payload.params
    }
  });
  yield put(appActions.setErrorId(airbrakeId));
}

function* handleLoginSaga() {
  const userId = yield select(userSelectors.getUserId);
  window.currentUser = { guid: userId };
}

function* handleLogoutSaga() {
  yield call(removeAllInstanceIds);
  window.currentUser = null;
  return sessionStorage.removeItem('sreDisabled');
}

function handleTokenReceived(action) {
  const { rememberMe } = action.payload;
  const cookieOptions = getCookieOptions();

  if (!rememberMe) {
    cookies.remove(cookieName, cookieOptions);
  }
};
//
// function handleTokenNotReceived() {
//   const cookieOptions = getCookieOptions();
//   cookies.remove(cookieName, cookieOptions);
// };

const getCookieOptions = () => {
  const extractHost = () => {
    const host = window.location.host.split(':')[0];
    let subdomainArray = host.split('.');
    if (subdomainArray.length > 1) {
      subdomainArray.shift();
    }
    return subdomainArray.join('.');
  };

  const cookieDomain = extractHost();
  let cookieOptions = {};
  // this is so development on localhost (which doesn't need a domain) works
  if (cookieDomain !== 'localhost') {
    cookieOptions.domain = cookieDomain;
  }
  return cookieOptions;
};

export function* appSagas() {
  yield takeEvery(HANDLE_ERROR, handleError);
  yield takeEvery(TOKEN_RECEIVED, handleTokenReceived);
  // yield takeEvery(TOKEN_NOT_RECEIVED, handleTokenNotReceived);
  yield takeLatest(INITIALIZE_APP, initAppSaga);
  yield takeLatest(LOGOUT, handleLogoutSaga);
  yield takeLatest(USER_INITIALIZED, handleLoginSaga);
  yield takeLatest(SET_APP_FONT_FOR_LANGUAGE, setAppFontForLanguageSaga);
  yield takeLatest(INSTALL_LION_LANGUAGE, setLionLanguageSaga);
}
