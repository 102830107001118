export const INITIALIZE_APP = 'INITIALIZE_APP';
export const HANDLE_ERROR = 'HANDLE_ERROR';
const SET_CURRENT_TOOLTIP = 'SET_CURRENT_TOOLTIP';
export const SET_APP_FONT_FOR_LANGUAGE = 'SET_APP_FONT_FOR_LANGUAGE';
export const INSTALL_LION_LANGUAGE = 'INSTALL_LION_LANGUAGE';
const SET_ERROR_ID = 'SET_ERROR_ID';

const APP_INITIALIZED = 'APP_INITIALIZED';

const initialAppState = {
  initialized: false,
  currentTooltip: null,
  appFont: null,
  errorId: null
};

export const appReducer = (appState = initialAppState, action = null) => {
  switch (action && action.type) {
    // these actions only want side-effects
    case INITIALIZE_APP:
    case INSTALL_LION_LANGUAGE:
    case HANDLE_ERROR:
      return appState;

    case APP_INITIALIZED:
      return {
        ...appState,
        initialized: true
      };

    case SET_CURRENT_TOOLTIP:
      return {
        ...appState,
        currentTooltip: action.payload.tooltip
      };

    case SET_APP_FONT_FOR_LANGUAGE:
      let appFont = 'Effra, "Helvetica Neue", Helvetica, Arial, sans-serif';
      if (action.payload.languageCode === 'lui') {
        appFont = 'helfa';
      }
      if (action.payload.languageCode === 'oji') {
        appFont = "'Helvetica Neue', Helvetica, Arial, sans-serif";
      }
      // cannot have 'Helvetica Neue' as a back up here - overwrites bold i̲ in cic??!
      if (action.payload.languageCode === 'cic') {
        appFont = "'Noto Sans', Helvetica, Arial, sans-serif";
      }

      return {
        ...appState,
        appFont: appFont
      };

    case SET_ERROR_ID:
      return {
        ...appState,
        errorId: action.payload.errorId || null
      };

    default:
      return appState;
  }
};

export const appActions = {
  initializeApp: (queryParams) => ({ type: INITIALIZE_APP, payload: { queryParams: queryParams } }),
  setAppInitialized: () => ({ type: APP_INITIALIZED }),
  handleError: (errorStr, params) => ({
    type: HANDLE_ERROR,
    payload: { message: errorStr, params: params }
  }),
  setAppFontForLanguage: (languageCode) => ({
    type: SET_APP_FONT_FOR_LANGUAGE,
    payload: { languageCode: languageCode }
  }),
  installLionLanguage: (locale) => ({
    type: INSTALL_LION_LANGUAGE,
    payload: { locale: locale }
  }),
  setCurrentTooltip: (tooltip) => ({ type: SET_CURRENT_TOOLTIP, payload: { tooltip: tooltip } }),
  setErrorId: (errorId) => ({ type: SET_ERROR_ID, payload: { errorId } })
};

export const appSelectors = {
  isAppInitialized: (state) => state.app.initialized,
  appFont: (state) => state.app.appFont,
  currentTooltip: (state) => state.app.currentTooltip,
  errorId: (state) => state.app.errorId
};
